<!-- src/components/SidebarMenu.vue -->
<template>
    <v-navigation-drawer
        app
        expand-on-hover
        rail
        color="grey-lighten-3"
        permanent
    >
        <v-list class="d-flex flex-column" style="height: 100%;">
            <v-list>
                <v-list-item 
                    :prepend-avatar="require('@/assets/Texaco_textlogo_small.png')"
                    :title="user">
                    
                </v-list-item>
            </v-list>

            <!-- <v-divider></v-divider> -->

            <v-list density="compact" nav>
                <v-list-item prepend-icon="mdi-view-dashboard" title="Overzicht" value="overzicht" to="/" color=#FF0000>
                </v-list-item>
                <v-list-item prepend-icon="mdi-tanker-truck" title="Leveringen" value="leveringen" to="/invoices" color=#FF0000>
                </v-list-item>
                <v-list-item prepend-icon="mdi-currency-eur" title="Platts" value="platts" to="/platts" color=#FF0000>
                </v-list-item>
            </v-list>

            <v-spacer></v-spacer>

            <v-list>
                <v-list-item prepend-icon="mdi-power" title="Uitloggen" @click.prevent="handle_logout" color=#FF0000>
                </v-list-item>
                <v-list-item
                    prepend-avatar="require('@/assets/IDontExistAndThisIsADirtyHack.png')">
                    <v-list-item-content>
                        <v-list-item-title class="version-info">
                            Versie: {{ version }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-list>
    </v-navigation-drawer>
</template>
  
<script>
    import auth from '../auth';

    export default {
        
        name: "SidebarMenu",
        data() {
            return {
                user: sessionStorage.getItem("user"),
                version: "1.0.8"
            };
        },
        methods: {
            async handle_logout() {
                auth.logout();              
                this.$store.commit('setIsLoggedIn', false);
                await this.$router.push({ name: 'Login' });
            },
        },
    };
</script>
  
<style scoped>
/* Add any custom styles for the sidebar menu here */
.version-info {
    font-size: 12px;
    color: grey;
}
</style>
  